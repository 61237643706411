.treatment {
  cursor: move;

  &:before {
    content: '\f0c9';
    font-family: FontAwesome;
    color: #AAA;
    font-size: 18px;
    float: left;
    margin-top: 24px;
    padding-right: 15px;
  }

  .media-object {
    width: 120px;
    height: 88px;
  }

  a {
    text-decoration: none;
    color: #000;

    h4 {
      color: #000;
    }
  }

  .field {
    font-size: 12px;
  }
}

.subtreatment {
  .drag {
    cursor: move;
  }

  a {
    display: block;
    text-decoration: none;
    color: #000;
    width: 100%;
    height: 100%;
  }
}

.treatment-form {
  max-width: 710px;
}

.sortable-ghost {
  opacity: 0.15 !important;
}